<template>
  <v-list subheader>
    <div v-if="!documents || documents.length < 1">Il n'y a aucun document à afficher.</div>
    <div v-else>
      <template v-for="(category, c) in Object.keys(documents)">
        <div :key="c">
          <v-subheader class="text-h6 black--text font-weight-bold mt-4 mb-2">
            {{ category }}
          </v-subheader>
          <template v-for="(file, f) in documents[category]">
            <div :key="f">
              <v-list-item>
                <v-list-item-icon>
                  <v-icon color="black">mdi-file-pdf-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ file.file.name }}
                </v-list-item-content>
                <v-list-item-action>
                  <slot name="actions" :file="file"></slot>
                </v-list-item-action>
              </v-list-item>
              <v-divider />
            </div>
          </template>
        </div>
      </template>
    </div>
  </v-list>
</template>

<script>
export default {
  name: 'DocumentList',
  mounted() {
    this.documents = this.filterDocs();
  },

  data: () => ({
    documents: null,
  }),
  computed: {
    categories() {
      return Object.keys(this.value);
    },
  },
  watch: {
    value: {
      handler() {
        this.documents = this.filterDocs();
      },
      deep: true,
    },
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
  },
  methods: {
    filterDocs() {
      const results = {};

      this.value.forEach((doc) => {
        if (!results[doc.category]) {
          results[doc.category] = [doc];
        } else {
          results[doc.category].push(doc);
        }
      });

      return results;
    },
    filterDocs2(docs) {
      const results = {};

      docs.forEach((doc) => {
        if (!results[doc.category]) {
          results[doc.category] = [doc];
        } else {
          results[doc.category].push(doc);
        }
      });

      return results;
    },
  },
};
</script>

<style>

</style>
